<template>
  <div class="introductionReport">
    <div class="search" style="display: block">
      <div class="group-search">
        <span class="group-label">中心</span>
        <a-tree-select
          class="search-style"
          style="width: 300px"
          v-model="OrgIds"
          :tree-data="treeData"
          tree-checkable
          :maxTagCount="1"
          placeholder="请选择搜索范围"
          @change="change"
          :show-checked-strategy="SHOW_PARENT"
        />
      </div>
      <div class="group-search">
        <span class="group-label">时间类型</span>
        <a-select
          class="search-style"
          default-value="1"
          style="width: 120px"
          @change="ChangeType"
          placeholder="时间类型"
        >
          <a-select-option value="1">
            签单时间
          </a-select-option>
          <a-select-option value="2">
            创建时间
          </a-select-option>
        </a-select>
      </div>

      <div class="group-search">
        <span class="group-label">时间范围</span>
        <a-range-picker
          :value="createValue1"
          class="search-style"
          style="width: 200px"
          format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"
          allowClear
          @change="ChangeSearchTime"
        />
      </div>
      <!--<div class="group-search">-->
      <!--<span class="group-label">创建时间</span>-->
      <!--<a-range-picker :value="createValue2" class="search-style" style="width: 200px" format="YYYY-MM-DD"  :placeholder="['开始时间','结束时间']"   allowClear @change="ChangeCreateTime"  />-->
      <!--</div>-->

      <a-button style="margin-right: 15px" @click="_clear">重置</a-button>
      <a-button type="primary" @click="_search">查询</a-button>
    </div>
    <a-table
      :pagination="false"
      v-if="data"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: 500 }"
    >
      <div slot="Id" slot-scope="text, record, index">
        <a-icon
          style="font-size: 20px;margin-right: 8px;"
          @click="_info(record)"
          type="snippets"
        />
      </div>
    </a-table>
    <p style="margin-top: 20px">
      对介绍签单率的说明: <br />
      ①选的签单时间<br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;转介绍总数 :
      签单时间在某个区间的转介绍总数 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同事介绍总数 :
      签单时间在某个区间的同事介绍总数 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;会员介绍总数 :
      签单时间在某个区间的会员介绍总数<br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;签单金额 :
      签单时间在某个区间的成交金额 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;成交人数 :
      签单时间在某个区间的成交人数 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;会员介绍率 = 会员介绍总数 / 转介绍总数
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;介绍签单率 =
      签单时间在某个区间的转介绍签单 / 签单时间在某个区间的总签单数 <br />

      ②选的创建时间<br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;转介绍总数 :
      名单创建时间在某个区间的转介绍名单数<br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同事介绍总数 :
      名单创建时间在某个区间的同事介绍总数 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;会员介绍总数 :
      名单创建时间在某个区间的会员介绍总数 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;签单金额 :
      名单创建时间在某个区间的签单金额 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;成交人数 :
      名单创建时间在某个区间的成交人数 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;会员介绍率 = 会员介绍总数 / 转介绍总数
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;介绍签单率 =
      名单创建时间在某个区间的转介绍签单数 /
      名单创建时间在某个区间的所有签单数<br />
    </p>

    <a-drawer
      :title="visibleName"
      placement="right"
      :visible="visible"
      :width="900"
      @close="onClose"
    >
      <div style="margin-bottom: 60px">
        <div class="search" style="display: block">
          <div class="group-search">
            <span class="group-label">时间类型</span>
            <a-select
              class="search-style"
              :default-value="dateType"
              style="width: 120px"
              @change="ChangeType"
              placeholder="时间类型"
            >
              <a-select-option value="1">
                签单时间
              </a-select-option>
              <a-select-option value="2">
                创建时间
              </a-select-option>
            </a-select>
          </div>
          <div class="group-search">
            <span class="group-label">时间范围</span>
            <a-range-picker
              v-if="StartDate"
              :default-value="[
                moment(StartDate, 'YYYY-MM-DD'),
                moment(EndDate, 'YYYY-MM-DD')
              ]"
              class="search-style"
              style="width: 200px"
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              allowClear
              @change="ChangeSearchSign"
            />
            <a-range-picker
              v-else
              :value="createValue3"
              class="search-style"
              style="width: 200px"
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              allowClear
              @change="ChangeSearchSign"
            />
          </div>
          <a-button style="margin-right: 15px" @click="_clearInfo"
            >重置</a-button
          >
          <a-button type="primary" @click="_searchInfo">查询</a-button>
        </div>
        <a-table
          :pagination="false"
          v-if="infoData"
          :columns="infocolumns"
          :data-source="infoData"
        >
        </a-table>
        <a-pagination
          v-if="total"
          style="margin-top: 35px;text-align: right;"
          @change="ChangePage"
          :pageSize="pagesize"
          :total="total"
          show-less-items
        />
      </div>

      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
      >
        <a-button type="primary" style="marginRight: 8px" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>

    <div v-show="spinning" style="position:fixed;left:50%;">
      <a-spin style="margin: 150px 0%;">
        <a-icon
          slot="indicator"
          type="loading"
          :spinning="spinning"
          style="font-size: 44px;color:red;"
          spin
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import G2 from '@antv/g2'
// import { TreeSelect } from 'ant-design-vue';
import moment from 'moment'
export default {
  name: 'introductionReport',
  data () {
    return {
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      chartData: [
        { genre: 'Sports', sold: 275 },
        { genre: 'Strategy', sold: 115 },
        { genre: 'Action', sold: 120 },
        { genre: 'Shooter', sold: 350 },
        { genre: 'Fight', sold: 150 },
        { genre: 'Narration', sold: 23 },
        { genre: 'Race', sold: 223 },
        { genre: 'Advanture', sold: 57 },
        { genre: 'Puzzle', sold: 109 },
        { genre: 'Music', sold: 555 }
      ],
      createValue1: [],
      createValue2: [],
      columns: [
        {
          title: '中心',
          dataIndex: 'OrgName',
          key: 'OrgName',
          align: 'left',
          width: 280
        },
        {
          title: '转介绍总数',
          dataIndex: 'IntroduceTotalCount',
          key: 'IntroduceTotalCount',
          align: 'center',
          width: 120
        },
        {
          title: '同事介绍总数',
          dataIndex: 'ColleagueIntroduceNum',
          key: 'ColleagueIntroduceNum',
          align: 'center',
          width: 120
        },
        {
          title: '会员介绍总数',
          dataIndex: 'MemberIntroduceNum',
          key: 'MemberIntroduceNum',
          align: 'center',
          width: 120
        },
        {
          title: '签单总金额',
          dataIndex: 'TotalSignContractCost',
          key: 'TotalSignContractCost',
          align: 'center',
          width: 140
        },
        {
          title: '总成交人数',
          dataIndex: 'TotalSignCount',
          key: 'TotalSignCount',
          align: 'center',
          width: 120
        },
        {
          title: '转介绍签单人数',
          dataIndex: 'IntroduceTotalSignCount',
          key: 'IntroduceTotalSignCount',
          align: 'center',
          width: 140
        },

        {
          title: '会员介绍率',
          dataIndex: 'MemberIntroduceRate',
          key: 'MemberIntroduceRate',
          align: 'center',
          width: 120
        },
        {
          title: '介绍签单率',
          dataIndex: 'IntroduceSignRate',
          key: 'IntroduceSignRate',
          align: 'center',
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'Id',
          key: 'Id',
          align: 'center',
          scopedSlots: { customRender: 'Id' }
        }
      ],
      data: '',
      startSignDate: '',
      endSignDate: '',
      endCreateTime: '',
      startCreateTime: '',
      spinning: false,
      visibleName: '',
      visible: false,
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      treeData: [
        {
          title: 'Node1',
          value: '7520669f3b20008c6a6df71e9cebc2f9',
          key: '3',
          children: [
            {
              title: 'Child Node1',
              value: '7520669f3b20008c6a6df71e9cebc2f944',
              key: '4'
            }
          ]
        },
        {
          title: 'Node2',
          value: '6',
          key: '7',
          children: [
            {
              title: 'Child Node3',
              value: '7520669f3b20008c6a6df71e9cebc2f9sdfs',
              key: '8'
            },
            {
              title: 'Child Node4',
              value: '7520669f3b20008c6a6df71e9cebc2f9wee',
              key: '9'
            },
            {
              title: 'Child Node5',
              value: '7520669f3b20008c6a6df71e9cebc2f9weffds',
              key: '1'
            }
          ]
        }
      ],

      OrgIds: [],
      OrgName: [],
      ParentId: [],
      SHOW_PARENT: 'SHOW_ALL',
      infoData: '',
      infocolumns: [
        {
          title: '中心',
          dataIndex: 'SchoolName',
          key: 'SchoolName',
          align: 'center',
          width: 200
        },
        {
          title: '会员名称',
          dataIndex: 'StudentName',
          key: 'StudentName',
          align: 'center',
          width: 120
        },
        {
          title: '手机号码',
          dataIndex: 'UserName',
          key: 'UserName',
          align: 'center',
          width: 160
        },
        {
          title: '合同金额',
          dataIndex: 'ContractCost',
          key: 'ContractCost',
          align: 'center',
          width: 160
        },
        {
          title: '签单时间',
          dataIndex: 'SignDate',
          key: 'SignDate',
          align: 'center',
          width: 120
        },
        {
          title: '创建时间',
          dataIndex: 'CreateTime',
          key: 'CreateTime',
          align: 'center',
          width: 120
        }
      ],
      pagesize: 10,
      total: '',
      p: '',
      orgId: '',
      SearchstartSignDate: '',
      SearchendSignDate: '',
      createValue3: [],
      StartDate: '',
      EndDate: '',
      dateType: '1'
    }
  },
  created () {
    this._treeData()
    this.loadData()
  },
  methods: {
    moment,
    _treeData () {
      let self = this
      this.$axios.get(
        1090,
        {
          UserId: self.userinfo.uid
        },
        res => {
          self.treeData = res.data
          console.log(res)
        }
      )
    },
    change (value, label, text) {
      if (text.checked) {
        this.ParentId.push(text.triggerValue)
      } else {
        if (this.ParentId.length > 0) {
          for (var i = 0; i < this.ParentId.length; i++) {
            if (text.triggerValue == this.ParentId[i]) {
              this.ParentId.splice(i, 1)
            }
          }
        }
      }

      this.OrgName = label
      this.OrgIds = value
    },
    ChangeType (value) {
      this.dateType = value
    },
    loadData () {
      this.spinning = true
      let self = this
      this.$message.loading('加载中...')
      this.$axios.post(
        1101,
        {
          dateType: self.dateType,
          StartDate: self.StartDate,
          EndDate: self.EndDate,
          OrgIds: self.OrgIds.length > 0 ? JSON.stringify(self.OrgIds) : '',
          UserId: self.userinfo.uid,
          OrgName: self.OrgName.length > 0 ? JSON.stringify(self.OrgName) : '',
          ParentId:
            self.ParentId.length > 0 ? JSON.stringify(self.ParentId) : ''
        },
        res => {
          self.spinning = false
          console.log(res)
          // if(res.data.columns){
          //     self.columns = res.data.columns;
          // }
          self.data = res.data
          this.$message.destroy()
        }
      )
    },
    _search () {
      this.loadData()
    },
    _clear () {
      this.OrgIds = []
      this.OrgName = []
      this.dateType = ''
      this.StartDate = ''
      this.EndDate = ''
      this.createValue1 = []
      this.createValue2 = []
      this.startSignDate = ''
      this.endSignDate = ''
      this.ParentId = []
      this.loadData()
    },
    ChangeSearchTime (time, timeString) {
      this.createValue1 = time
      this.StartDate = timeString[0]
      this.EndDate = timeString[1]
    },
    ChangeCreateTime (time, timeString) {
      this.createValue2 = time
      this.startCreateTime = timeString[0]
      this.endCreateTime = timeString[1]
    },

    _info (value) {
      if (!this.orgId) {
        this.orgId = value.Id
        this.visibleName = value.OrgName
      }
      if (!this.SearchstartSignDate) {
        this.SearchstartSignDate = this.startSignDate
        this.SearchendSignDate = this.endSignDate
      }
      console.log(value)
      this.spinning = true
      let self = this

      this.$axios.get(
        1102,
        {
          OrgId: self.orgId,
          dateType: self.dateType,
          StartDate: self.StartDate,
          EndDate: self.EndDate,
          pagesize: self.pagesize,
          p: self.p
        },
        res => {
          self.spinning = false
          console.log(res)
          if (res.data.code == 1) {
            self.visible = true
            self.infoData = res.data.data
            self.total = res.data.total
          } else {
            self.infoData = ''
            self.total = ''
            self.orgId = ''
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    _clearInfo () {
      this.createValue3 = []
      this.StartDate = ''
      this.EndDate = ''
      this.p = ''
      this._info()
    },
    ChangeSearchSign (time, timeString) {
      this.createValue3 = time
      this.SearchstartSignDate = timeString[0]
      this.SearchendSignDate = timeString[1]
    },
    _searchInfo () {
      this._info()
    },
    ChangePage (value) {
      this.p = value
      this._info()
    },
    onClose () {
      this.orgId = ''
      this.createValue3 = []
      this.SearchstartSignDate = ''
      this.SearchendSignDate = ''
      this.visible = false
      this.p = ''
      this.StartDate = ''
      this.EndDate = ''
      this.visibleName = ''
    }
  }
}
</script>

<style scoped lang="less">
.introductionReport {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}
.search {
  margin-bottom: 20px;
  .group-search {
    display: inline-block;
    .group-label {
      margin-right: 8px;
    }
    .search-style {
      display: inline-block;
      width: 120px;
      margin-right: 15px;
    }
  }
}
</style>
